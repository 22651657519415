<template>
  <header :class="['header_mb',{'active':!!select1depthMenu}]">

    <div class="header-top">
      <div class="logo">
        <a href="/" title="bms" class="top-nav-image-logo-link" target="_self"
           aria-label="bms"><img src="@/assets/images/BTNOn_logo.png" title="bms"
                                 class="top-nav-logo-image"
                                 alt="bms"/></a>
      </div>
      <button @click="closeMenu">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M0.219783 11.8869C-0.07311 12.1798 -0.07311 12.6547 0.219783 12.9476C0.512676 13.2405 0.98755 13.2405 1.28044 12.9476L6.58368 7.64436L11.8869 12.9476C12.1798 13.2405 12.6547 13.2405 12.9476 12.9476C13.2405 12.6547 13.2405 12.1798 12.9476 11.887L7.64434 6.5837L12.9477 1.28033C13.2406 0.987437 13.2406 0.512563 12.9477 0.21967C12.6548 -0.0732233 12.1799 -0.0732233 11.887 0.21967L6.58368 5.52304L1.28033 0.219686C0.987437 -0.0732071 0.512563 -0.0732072 0.21967 0.219686C-0.0732234 0.512579 -0.0732232 0.987453 0.21967 1.28035L5.52302 6.5837L0.219783 11.8869Z"
                fill="#595454"/>
        </svg>
      </button>
    </div>

    <div class="search-panel" v-if="!(!!select1depthMenu)">
      <input type="text" placeholder="검색어를 입력해 주세요" v-model="searchKeyword"
             @keyup.enter="$emit('getSearchData', searchKeyword)">
      <a @click="$emit('getSearchData', searchKeyword)"
         title="Title" aria-label="Action Label" target=""
         class="btn top-nav-search-trigger open-modal top-nav-search" data-target="modal2"
         data-arialabel="arialabel" data-classname="top-nav-search-modal generic-modal full-screen "
         data-close-title="close Title">
        <div class="top-nav-search-link">
          <i class="icon icon-search-v2">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.1203 6.81017C12.1203 9.7429 9.7429 12.1203 6.81017 12.1203C3.87745 12.1203 1.5 9.7429 1.5 6.81017C1.5 3.87745 3.87745 1.5 6.81017 1.5C9.7429 1.5 12.1203 3.87745 12.1203 6.81017ZM11.0662 12.1269C9.90036 13.0614 8.42054 13.6203 6.81017 13.6203C3.04902 13.6203 0 10.5713 0 6.81017C0 3.04902 3.04902 0 6.81017 0C10.5713 0 13.6203 3.04902 13.6203 6.81017C13.6203 8.42057 13.0614 9.90042 12.1269 11.0663L16.8747 15.8141L15.814 16.8748L11.0662 12.1269Z"
                    fill="#595454"></path>
            </svg>
          </i>
        </div>
      </a>
    </div>

    <nav :class="['main-nav-mb',{'active':!!select1depthMenu}]">
      <template v-if="!(!!select1depthMenu)">
        <template v-for="menu in menu1depthList">
          <router-link :key="menu.key"
                       :to="menu.href" :title="menu.name" :arial-label="menu.name"
                       @click.native="closeMenu">
            <p class="nav-item-text" v-html="menu.name"/>
            <button v-if="menu.depth" @click.stop.prevent="select1depthMenu = menu.key">
              <i class="icon">
                <svg class="icon-svg" viewBox="0 0 30 50">
                  <title>Next Page Icon</title>
                  <path
                      d="M28.45,23.48,4.43,1.59a2,2,0,0,0-2.86,0,2,2,0,0,0,0,2.84L24.14,25,1.57,45.57a2,2,0,0,0,0,2.84,2,2,0,0,0,2.86,0l24-21.89a2.31,2.31,0,0,0,0-3Z">
                  </path>
                </svg>
              </i>
            </button>
          </router-link>
        </template>
      </template>
      <a :title="menu1depthList[select1depthMenu].name"
         :arial-label="menu1depthList[select1depthMenu].name"
         v-if="!!select1depthMenu && !(!!select2depthMenu)"
         @click="select1depthMenu = null">
        <button>
          <i class="icon">
            <svg class="icon-svg" viewBox="0 0 30 50">
              <title>Previous Page Icon</title>
              <path
                  d="M5.86,25,28.43,4.43a2,2,0,0,0,0-2.84,2,2,0,0,0-2.86,0l-24,21.89a2.31,2.31,0,0,0,0,3l24,21.89a2,2,0,0,0,2.86,0,2,2,0,0,0,0-2.84Z">
              </path>
            </svg>
          </i>
        </button>
        <p class="nav-item-text" v-html="menu1depthList[select1depthMenu].name"/>
      </a>
      <a :title="menu2depthList[select1depthMenu][select2depthMenu].name"
         :arial-label="menu2depthList[select1depthMenu][select2depthMenu].name"
         v-if="!!select2depthMenu"
         @click="select2depthMenu = null">
        <button>
          <i class="icon">
            <svg class="icon-svg" viewBox="0 0 30 50">
              <title>Previous Page Icon</title>
              <path
                  d="M5.86,25,28.43,4.43a2,2,0,0,0,0-2.84,2,2,0,0,0-2.86,0l-24,21.89a2.31,2.31,0,0,0,0,3l24,21.89a2,2,0,0,0,2.86,0,2,2,0,0,0,0-2.84Z">
              </path>
            </svg>
          </i>
        </button>
        <p class="nav-item-text"
           v-html="menu2depthList[select1depthMenu][select2depthMenu].name"></p>
      </a>
      <v-slide-x-transition mode="out-in" hide-on-leave>
        <div class="sub-nav" v-if="!!select1depthMenu && !(!!select2depthMenu)">
          <div class="level2">

            <div class="level2-sub-tem nav-dropdown"
                 v-for="menu2 in menu2depthList[select1depthMenu]" :key="menu2.key">
              <a class="nav-item-level2" :title="menu2.name"
                 :arial-label="menu2.name"
                 @click="closeMenu(menu2.name)"
                 v-if="menu2.external">
                <span class="nav-item-text"
                      v-html="`${menu2.name} <img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"> </span>
                <button v-if="menu2.depth" @click.stop.prevent="select2depthMenu = menu2.key">
                  <i class="icon">
                    <svg class="icon-svg" viewBox="0 0 30 50">
                      <title>Next Page Icon</title>
                      <path
                          d="M28.45,23.48,4.43,1.59a2,2,0,0,0-2.86,0,2,2,0,0,0,0,2.84L24.14,25,1.57,45.57a2,2,0,0,0,0,2.84,2,2,0,0,0,2.86,0l24-21.89a2.31,2.31,0,0,0,0-3Z">
                      </path>
                    </svg>
                  </i>
                </button>
              </a>
              <router-link :to="menu2.href" target="_self" class="nav-item-level2"
                           :title="menu2.name"
                           :arial-label="menu2.name"
                           @click.native="closeMenu(menu2.name)"
                           v-else>
                <span class="nav-item-text" v-html="menu2.name"/>
                <button v-if="menu2.depth" @click.stop.prevent="select2depthMenu = menu2.key">
                  <i class="icon">
                    <svg class="icon-svg" viewBox="0 0 30 50">
                      <title>Next Page Icon</title>
                      <path
                          d="M28.45,23.48,4.43,1.59a2,2,0,0,0-2.86,0,2,2,0,0,0,0,2.84L24.14,25,1.57,45.57a2,2,0,0,0,0,2.84,2,2,0,0,0,2.86,0l24-21.89a2.31,2.31,0,0,0,0-3Z">
                      </path>
                    </svg>
                  </i>
                </button>
              </router-link>
            </div>
          </div>
          <div class="level3-empty active"></div>
        </div>
      </v-slide-x-transition>
      <v-slide-x-transition mode="out-in" hide-on-leave>
        <div class="sub-nav" v-if="!!select2depthMenu">
          <div class="level2">
            <div class="level2-sub-tem nav-dropdown">
              <div class="level3">
                <div class="level3-nav-item">
                  <div class="level3-nav-sub" v-for="menu3 in menu3depthList[select2depthMenu]"
                       :key="menu3.key">
                    <template v-if="menu3.external">
                      <a v-if="menu3.noPopup" :href="menu3.href" class="nav-item-level3"
                         target="_blank"
                      >
                        <span class="level3-item-text"
                              v-html="`${menu3.name} <img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"/>
                      </a>
                      <a v-else class="nav-item-level3"
                         @click.native="closeMenu(menu3.name)">
                        <span class="level3-item-text"
                              v-html="`${menu3.name} <img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"/>
                      </a>
                    </template>
                    <router-link v-else :to="menu3.href" class="nav-item-level3"
                                 @click.native="closeMenu(menu3.name)">
                      <span class="level3-item-text" v-html="menu3.name"/>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </v-slide-x-transition>
    </nav>

    <div class="member_box">
      <a @click="logout" v-if="isLogin" key="logout">Logout</a>
      <a :href="loginUrl" v-else key="login">Login</a>
      <router-link to="/my-page" @click.native="closeMenu">My Page</router-link>
      <router-link to="/contact-us" @click.native="closeMenu">Contact Us</router-link>
    </div>
  </header>
</template>

<script>

export default {
  name: "MobileHeader",
  props: {
    isLogin: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    logout() {
      this.$emit('logout')
      this.closeMenu()
    },
    closeMenu(name) {
      this.visibleMenu = false;
      this.$emit('show-mobile-menu-open');
      this.select1depthMenu = '';
      this.select2depthMenu = '';
      this.select3depthMenu = '';
      if (name === "Pipeline at a Glance") {
        this.$emit('show-external-modal',
            {setUrl: 'https://www.bms.com/researchers-and-partners/in-the-pipeline.html'})
      }
      if (name === "Pre-Approval Access") {
        this.$emit('show-external-modal',
            {setUrl: 'https://www.bms.com/healthcare-providers/early-patient-access-to-investigational-medicine/investigational-drugs-available.html'})
      }
    },
  },
  data() {
    return {
      searchKeyword: '',
      select1depthMenu: '',
      select2depthMenu: '',
      select3depthMenu: '',
    }
  },
  computed: {
    getEnvHost() {
      return `?redirectUrl=${window.location.origin}${this.$route.query.redirect ?? ''}`
    },
    redirectUrl() {
      return `${window.location.origin}${this.$route.query.redirect ?? ''}`
    },
    loginUrl() {
      let url = new URL(process.env.VUE_APP_LOGIN_URL)
      url.searchParams.set('redirectUrl', this.redirectUrl)
      return url
    },
    menu1depthList() {
      return {
        'product': {
          name: '제품',
          href: '/menu/inrebic',
          key: 'product',
          depth: true,
          external: false,
        },
        'webinar': {
          name: '웨비나 & VOD',
          href: '/webinar-symposium/webinar-live',
          key: 'webinar',
          depth: true,
          external: false,
        },
        'requestService': {
          name: 'BMS 직원방문 및 연락 요청',
          href: '/request-service',
          key: 'requestService',
          depth: false,
          external: false,
        },
        'news': {
          name: 'BMS 소식',
          href: '/news/news-and-notice',
          key: 'news',
          depth: true,
          external: false,
        },
        'requestReport': {
          name: '의학정보요청 및 이상사례보고',
          href: '/request-report',
          key: 'requestReport',
          depth: false,
          external: false,
        },
        'pipeline': {
          name: '파이프라인',
          href: '#',
          key: 'pipeline',
          depth: true,
          external: false,
        }
      }
    },
    menu2depthList() {
      return {
        'product': {
          'Hematology': {
            name: 'Hematology',
            key: 'Hematology',
            href: '/menu/inrebic',
            depth: true,
            external: false,
          },
          'Oncology': {
            name: 'Oncology',
            key: 'Oncology',
            href: '/menu/opdivo-yervoy',
            depth: true,
            external: false,
          },
          'Immunology': {
            name: 'Immunology',
            key: 'Immunology',
            href: '/menu/orencia/',
            depth: true,
            external: false,
          },
          'Virology': {
            name: 'Virology',
            key: 'Virology',
            href: '/menu/baraclude/',
            depth: true,
            external: false,
          },
          'Cardiology': {
            name: 'Cardiology',
            key: 'Cardiology',
            href: '/menu/eliquis/',
            depth: true,
            external: false,
          },
        },
        'webinar': {
          'webinarLive': {
            name: '웨비나 라이브',
            key: 'webinarLive',
            href: '/webinar-symposium/webinar-live',
            depth: false,
            external: false,
          },
          'webinarVOD': {
            name: '웨비나 다시보기',
            key: 'webinarVOD',
            href: '/webinar-symposium/webinar-vod',
            depth: false,
            external: false,
          },
          'symposiumVOD': {
            name: 'VOD Contents',
            key: 'symposiumVOD',
            href: '/webinar-symposium/symposium-vod',
            depth: false,
            external: false,
          },
          // 'userGuide': {
          //   name: '이용안내',
          //   key: 'userGuide',
          //   href: '/webinar-symposium/user-guide',
          //   depth: false,
          //   external: false,
          // }
        },
        'news': {
          'newsAndNotice': {
            name: '뉴스 & 공지사항',
            key: 'newsAndNotice',
            href: '/news/news-and-notice',
            depth: false,
            external: false,
          },
          'socialContribute': {
            name: '사회공헌활동',
            key: 'socialContribute',
            href: '/news/social-contribute',
            depth: false,
            external: false,
          },
          'surveyEvent': {
            name: '설문조사참여',
            key: 'surveyEvent',
            href: '/news/survey-event',
            depth: false,
            external: false,
          },
          'webMagazine': {
            name: 'BMS 웹매거진',
            key: 'webMagazine',
            href: '/news/web-magazine',
            depth: false,
            external: false,
          }
        },
        'pipeline': {
          'pipelineAtAGlance': {
            name: 'Pipeline at a Glance',
            key: 'pipelineAtAGlance',
            href: 'https://www.bms.com/researchers-and-partners/in-the-pipeline.html',
            external: true,
            depth: false,
          },
          // 'pipelineTrials': {
          //   name: 'Pipeline Trials',
          //   key: 'pipelineTrials',
          //   href: 'https://www.bmsscience.com/pipeline-trials/',
          //   external: true,
          //   depth: false,
          // },
          'pre-ApprovalAccess': {
            name: 'Pre-Approval Access',
            key: 'pre-ApprovalAccess',
            href: 'https://www.bms.com/healthcare-providers/early-patient-access-to-investigational-medicine/investigational-drugs-available.html',
            external: true,
            depth: false,
          },
        }
      }
    },
    menu3depthList() {
      return this.isLogin && {
        'Hematology': [
          {
            name: 'INREBIC<sup>®</sup>(fedratinib) ',
            href: '/menu/inrebic',
            external: false,
          },
          {
            name: 'ONUREG<sup>®</sup>(azacitidine) ',
            href: '/menu/onureg',
            external: false,
            // href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/onureg-pi.pdf',
          },
          {
            name: 'REBLOZYL<sup>®</sup>(luspatercept-aamt) ',
            href: '/menu/reblozyl',
            external: false,
            // href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/reblozyl-20220512-pi.pdf',
          },
          {
            name: 'REVLIMID<sup>®</sup>(lenalidomide)/POMALYST<sup>®</sup>(pomalidomide)',
            href: '/menu/revlimid-pomalyst',
            external: false,
          },
          {
            name: 'SPRYCEL<sup>®</sup>(dasatinib)',
            href: '/menu/sprycel',
            external: false,
          },
          {
            name: 'THALIDOMIDE CELGENE<sup>®</sup>(thalidomide) ',
            external: true,
            noPopup: true,
            href: 'https://media2.celgene.com/content/uploads/sites/22/Thalidomide_Korea_PIL.pdf',
          },
          {
            name: 'VIDAZA<sup>®</sup>(azacitidine)',
            href: '/menu/vidaza',
            external: false,
          },
        ],
        'Oncology': [
          {
            name: 'OPDIVO<sup>®</sup> (nivolumab) / YERVOY<sup>®</sup> (ipilimumab)',
            href: '/menu/opdivo-yervoy',
            external: false,
          },
          {
            name: 'ABRAXANE<sup>®</sup> <span>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</span>',
            href: '/menu/abraxane',
            external: false,
          },
          // {
          //   name: 'TAXOL<sup>®</sup>(paclitaxel)',
          //   external: true,
          //   noPopup: true,
          //   href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/taxol-pi.pdf',
          // },
        ],
        'Immunology': [
          {
            name: 'ZEPOSIA<sup>®</sup>(ozanimod)',
            href: '/menu/zeposia',
            external: false,
          },
          {
            name: 'ORENCIA<sup>®</sup>(abatacept)',
            href: '/menu/orencia',
            external: false,
          },
          {
            name: 'SOTYKTU<sup>™</sup>(deucravacitinib)',
            href: '/menu/sotyktu',
            external: false,
          },
        ],
        'Virology': [
          {
            name: 'BARACLUDE<sup>®</sup>(entecavir)',
            href: '/menu/baraclude',
            external: false,
          },
          {
            name: 'REYATAZ<sup>®</sup>(atazanavir) ',
            external: true,
            noPopup: true,
            href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/reyataz-pi.pdf',
          },
          {
            name: 'EVOTAZ<sup>®</sup>(atazanavir and cobicistat) ',
            external: true,
            noPopup: true,
            href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/evotaz-pi.pdf',
          },
        ],
        'Cardiology': [
          {
            name: 'ELIQUIS<sup>®</sup>(apixaban)',
            href: '/menu/eliquis',
            external: false,
          },
          {
            name: 'CAMZYOS<sup>™</sup>(mavacamten)',
            href: '/menu/camzyos',
            external: false,
          },
        ],
      }
    },
  },
}
</script>

<style scoped>

</style>
