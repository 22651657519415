<template>
  <div class="site-top-nav">

    <header class="header">
      <div class="header-top-message">
        보건의료전문가가 아닌 경우, <span class="li_blo"></span> 한국BMS Official Site (https://www.bms.com/kr)을 방문해
        주세요.
      </div>
      <div class="header-top">
        <div class="logo">
          <a
              href="/"
              title="bms"
              class="top-nav-image-logo-link"
              target="_self"
              aria-label="bms">
            <img src="@/assets/images/BTNOn_logo.png" title="bms"
                 class="top-nav-logo-image" alt="bms"/>
          </a>
        </div>
        <div class="header-top-right">
          <div class="top-links">
            <a @click="$emit('logout')" v-if="isLogin" key="logout">Logout</a>
            <a :href="loginUrl" v-else key="login">Login</a>
            <router-link to="/my-page">My Page</router-link>
            <router-link to="/contact-us">Contact Us</router-link>
          </div>
        </div>
        <div class="top-nav-bar">
          <button type="button" class="top-nav-bar-menu-trigger" @click="$emit('show-mobile-menu-open')">
            <div class="top-nav-bar-menu-icons">
              <i class="icon icon-menu-toggle">
                <svg width="18" height="16" viewBox="0 0 18 16" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <rect y="14" width="18" height="1.5" rx="0.75" fill="#595454"/>
                  <rect y="7" width="18" height="1.5" rx="0.75" fill="#595454"/>
                  <rect width="18" height="1.5" rx="0.75" fill="#595454"/>
                </svg>
              </i>
              <i class="icon icon-close-v2">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M0.219783 11.8869C-0.07311 12.1798 -0.07311 12.6547 0.219783 12.9476C0.512676 13.2405 0.98755 13.2405 1.28044 12.9476L6.58368 7.64436L11.8869 12.9476C12.1798 13.2405 12.6547 13.2405 12.9476 12.9476C13.2405 12.6547 13.2405 12.1798 12.9476 11.887L7.64434 6.5837L12.9477 1.28033C13.2406 0.987437 13.2406 0.512563 12.9477 0.21967C12.6548 -0.0732233 12.1799 -0.0732233 11.887 0.21967L6.58368 5.52304L1.28033 0.219686C0.987437 -0.0732071 0.512563 -0.0732072 0.21967 0.219686C-0.0732234 0.512579 -0.0732232 0.987453 0.21967 1.28035L5.52302 6.5837L0.219783 11.8869Z"
                        fill="#595454"/>
                </svg>
              </i>
            </div>
          </button>
        </div>
      </div>
      <div class="nav-search-mobile">
        <div class="nav-search-box">
          <input placeholder="Search" class="top-nav-search-input nav-search-input-mobile"/>
          <button type="button" data-url="/search-results.html"
                  class="top-nav-mobile-search-button nav-search-button">
            <i class="icon icon-search-v2">
              <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M12.1203 6.81017C12.1203 9.7429 9.7429 12.1203 6.81017 12.1203C3.87745 12.1203 1.5 9.7429 1.5 6.81017C1.5 3.87745 3.87745 1.5 6.81017 1.5C9.7429 1.5 12.1203 3.87745 12.1203 6.81017ZM11.0662 12.1269C9.90036 13.0614 8.42054 13.6203 6.81017 13.6203C3.04902 13.6203 0 10.5713 0 6.81017C0 3.04902 3.04902 0 6.81017 0C10.5713 0 13.6203 3.04902 13.6203 6.81017C13.6203 8.42057 13.0614 9.90042 12.1269 11.0663L16.8747 15.8141L15.814 16.8748L11.0662 12.1269Z"
                      fill="#595454"/>
              </svg>
            </i>
          </button>
        </div>
      </div>

      <div class="header-bottom">

        <nav class="main-nav">
          <div v-for="(menu,index) in menuTree" class="top-nav-item-bar nav-dropdown" :key="index">
            <a v-if="menu.external" :href="menu.href" class="top-nav-item" :title="menu.name"
               :arial-label="menu.name"
               @click.native="closeOpenMenu"
               target="_blank"
               rel="noopener noreferrer">
              <span class="nav-item-text"
                    v-html="`${menu.name}<img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"/>
            </a>
            <router-link :to="menu.href" class="top-nav-item" target="_self" :title="menu.name" :arial-label="menu.name"
                         @click.native="closeOpenMenu">
              <span class="nav-item-text" v-html="menu.name"></span>
            </router-link>
            <div class="sub-nav" v-if="menu.child != null" v-show="visibleMenu">
              <div class="level2">
                <div v-for="(menu2, index2) in menu.child" class="level2-sub-tem nav-dropdown"
                     :key="`${index}-${index2}`">
                  <template v-if="menu2.disabled">
                    <a rel="noopener noreferrer" class="nav-item-level2 disabled" :title="menu2.name"
                       :arial-label="menu2.name">
                      <span class="nav-item-text" v-html="menu2.name"></span>
                    </a>
                  </template>
                  <template v-else>
                    <a v-if="menu2.external"
                       rel="noopener noreferrer" class="nav-item-level2" :title="menu2.name"
                       :arial-label="menu2.name"
                       @click="closeOpenMenu(menu2.name)">
                      <span class="nav-item-text"
                            v-html="`${menu2.name} <img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"></span>
                    </a>
                    <router-link v-else :to="menu2.href" target="_self" class="nav-item-level2" :title="menu2.name"
                                 :arial-label="menu2.name"
                                 :disabled="true"
                                 @click.native="closeOpenMenu">
                      <span class="nav-item-text" v-html="menu2.name"></span>
                    </router-link>
                  </template>

                  <button type="button" aria-label="Action Label"
                          class="top-nav-item-button-mobile"
                          v-if="menu2.child != null">
                    <div class="top-nav-item-button-inner">
                      <i class="icon icon-page-right">
                        <svg class="icon-svg" viewBox="0 0 30 50">
                          <title>Next Page Icon</title>
                          <path
                              d="M28.45,23.48,4.43,1.59a2,2,0,0,0-2.86,0,2,2,0,0,0,0,2.84L24.14,25,1.57,45.57a2,2,0,0,0,0,2.84,2,2,0,0,0,2.86,0l24-21.89a2.31,2.31,0,0,0,0-3Z">
                          </path>
                        </svg>
                      </i>
                      <!--<img src="images/nav_icon.png" alt="">-->
                    </div>
                  </button>
                  <div class="level3" v-if="menu2.child != null">
                    <div class="level3-nav-item">
                      <div class="level3-nav-sub" v-for="(menu3,index3) in menu2.child"
                           :key="`${index}-${index2}-${index3}`">
                        <template v-if="menu3.external">
                          <a v-if="menu3.noPopup" rel="noopener noreferrer" class="nav-item-level3" :title="menu3.name"
                             target="_blank"
                             :arial-label="menu3.name"
                             :href="menu3.href">
                            <span class="nav-item-text"
                                  v-html="`${menu3.name} <img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"></span>
                          </a>
                          <a v-else rel="noopener noreferrer" class="nav-item-level3" :title="menu3.name"
                             :arial-label="menu3.name"
                             @click="closeOpenMenu(menu3.name)">
                            <span class="nav-item-text"
                                  v-html="`${menu3.name} <img src='${require('@/assets/images/icon_link.png')}' alt=''/>`"></span>
                          </a>
                        </template>
                        <router-link v-else :to="menu3.href" target="_self" class="nav-item-level3"
                                     :title="menu3.title" :aria-label="menu3.title"
                                     @click.native="closeOpenMenu">
                          <span class="level3-item-text" v-html="menu3.name">
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div :class="['level3-empty',{'active':menu.depth>=3}]"></div>
              <div v-if="menu.depth<=2" class="level1-article"></div>
            </div>
            <div class="top-nav-overlay"></div>
          </div>
        </nav>

        <!-- Search button -->
        <div class="search-panel">
          <a @click="$emit('show-search-modal')" title="search" aria-label="Action Label" target=""
             class="btn top-nav-search-trigger open-modal top-nav-search" data-target="modal2"
             data-arialabel="arialabel"
             data-classname="top-nav-search-modal generic-modal full-screen "
             data-close-title="close search">
            <div class="top-nav-search-link">
              <i class="icon icon-search-v2">
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M12.1203 6.81017C12.1203 9.7429 9.7429 12.1203 6.81017 12.1203C3.87745 12.1203 1.5 9.7429 1.5 6.81017C1.5 3.87745 3.87745 1.5 6.81017 1.5C9.7429 1.5 12.1203 3.87745 12.1203 6.81017ZM11.0662 12.1269C9.90036 13.0614 8.42054 13.6203 6.81017 13.6203C3.04902 13.6203 0 10.5713 0 6.81017C0 3.04902 3.04902 0 6.81017 0C10.5713 0 13.6203 3.04902 13.6203 6.81017C13.6203 8.42057 13.0614 9.90042 12.1269 11.0663L16.8747 15.8141L15.814 16.8748L11.0662 12.1269Z"
                        fill="#595454"></path>
                </svg>
              </i>
            </div>
          </a>
        </div>
        <!-- Search overlay -->
        <div class="top-nav-search-overlay">
          <div class="top-nav-search-columns">
            <div class="top-nav-search-column">

              <!-- Search section -->
              <div class="top-nav-search">
                <div class="top-nav-search-box">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                       xmlns="http://www.w3.org/2000/svg" class="search-icon">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.1203 6.81017C12.1203 9.7429 9.7429 12.1203 6.81017 12.1203C3.87745 12.1203 1.5 9.7429 1.5 6.81017C1.5 3.87745 3.87745 1.5 6.81017 1.5C9.7429 1.5 12.1203 3.87745 12.1203 6.81017ZM11.0662 12.1269C9.90036 13.0614 8.42054 13.6203 6.81017 13.6203C3.04902 13.6203 0 10.5713 0 6.81017C0 3.04902 3.04902 0 6.81017 0C10.5713 0 13.6203 3.04902 13.6203 6.81017C13.6203 8.42057 13.0614 9.90042 12.1269 11.0663L16.8747 15.8141L15.814 16.8748L11.0662 12.1269Z"
                          fill="#595454"></path>
                  </svg>
                  <input aria-label="Site Search" placeholder="Search this site"
                         class="top-nav-search-input"
                         @keyup.enter="$emit('getSearchData')"/>
                  <button type="button"
                          @click="$emit('getSearchData')"
                          class="top-nav-search-button">Search
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg" class="chevron-icon">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.77549 4L0.0826091 0.940012C-0.0123075 0.861362 -0.0276965 0.717906 0.0482368 0.619594L0.460705 0.0855644C0.536638 -0.0127477 0.675139 -0.0286873 0.770056 0.0499624L4.86784 3.44546C4.95137 3.51468 5 3.61948 5 3.73028V4.26972C5 4.38052 4.95137 4.48532 4.86784 4.55454L0.770056 7.95004C0.675139 8.02869 0.536638 8.01275 0.460705 7.91444L0.0482368 7.38041C-0.0276965 7.28209 -0.0123074 7.13864 0.0826092 7.05999L3.77549 4Z"
                            fill="#595454"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </header>

    <!-------------------파이프라인 모달창------------------->
    <div class="modal" v-if="showModal">
      <!-- 사이트 이탈 Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="closeModal">close</a>
        </div>
        <p class="modal_tit">BMS 글로벌 웹사이트로 이동합니다.</p>
        <p class="modal_exp">현재 웹사이트를 벗어나 BMS 글로벌 웹사이트로 이동합니다.
          해당 웹사이트는 한국BMS제약이 아닌 BMS Global 본사에서 운영하는 웹사이트로 해당 웹사이트의 제품
          콘텐츠 중 일부는 국내 허가사항과 다를 수 있습니다. 외부 사이트 링크는 단지 BMSON 웹사이트
          사용자들에게 편의를 제공하기 위한 목적으로 제공합니다.</p>
        <div class="modal_btn">
          <span class="btn_modal" @click="closeModal({url:url})">계속하기</span>
          <span class="btn_modal" @click="closeModal">취소</span>
        </div>
      </div>
    </div>
    <!-------------------파이프라인 모달창------------------->
  </div>

</template>

<script>

export default {
  name: 'Header',
  props: {
    isMobileMenuVisible: {
      type: Boolean,
      default: false,
    },
    isLogin: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visibleMenu: true,
      isAfterLeaveMobileMenu: false,
      showModal: false,
      urlType: "",
      url: "",
    }
  },
  watch: {
    '$route'() {
      if (this.$route.query['open-modal']) {
        this.$nextTick(() => {
          this.switchModal()
        })
      }
    }
  },
  methods: {
    closeOpenMenu(name) {
      this.visibleMenu = false;
      setTimeout(this.openMenu, 300)

      if (name === "Pipeline at a Glance") {
        this.openModal(name)
      }
      if (name === "Pre-Approval Access") {
        this.openModal(name)
      }
    },

    openMenu() {
      this.visibleMenu = true;
    },
    switchModal() {
      this.showModal = !this.showModal;
      if (this.showModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    openModal(name) {
      this.urlType = name
      this.showModal = true;
      document.documentElement.classList.add("overflow-y-hidden");

      if (this.urlType === "Pipeline at a Glance") {
        this.url = "https://www.bms.com/researchers-and-partners/in-the-pipeline.html"
      }
      if (this.urlType === "Pre-Approval Access") {
        this.url = "https://www.bms.com/healthcare-providers/early-patient-access-to-investigational-medicine/investigational-drugs-available.html"
      }
    },
    closeModal({url}) {
      if (url) {
        window.open(url, '_blank');
      }
      document.documentElement.classList.remove("overflow-y-hidden");
      this.showModal = false;
    },
  },
  computed: {
    getEnvHost() {
      return `?redirectUrl=${window.location.origin}${this.$route.query.redirect ?? ''}`
    },
    redirectUrl() {
      return `${window.location.origin}${this.$route.query.redirect ?? ''}`
    },
    loginUrl() {
      let url = new URL(process.env.VUE_APP_LOGIN_URL)
      url.searchParams.set('redirectUrl', this.redirectUrl)
      return url
    },
    menuTree() {
      return [
        //--------------------------------------------------------------------
        //제품 시작
        {
          name: '제품',
          href: '/menu/inrebic',
          depth: 3,
          child: [
            {
              name: 'Hematology',
              href: '/menu/inrebic',
              child: this.isLogin && [
                {
                  name: 'INREBIC<sup>®</sup>(fedratinib)',
                  title: 'INREBIC®(fedratinib)',
                  // href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/Inrebic-pi.pdf',
                  href: '/menu/inrebic'
                },
                {
                  name: 'ONUREG<sup>®</sup>(azacitidine) ',
                  title: 'ONUREG®(azacitidine)',
                  href: '/menu/onureg'
                  // href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/onureg-pi.pdf',
                },
                {
                  name: 'REBLOZYL<sup>®</sup>(luspatercept-aamt) ',
                  title: 'REBLOZYL®(luspatercept-aamt)',
                  href: '/menu/reblozyl'
                  // href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/reblozyl-20220512-pi.pdf',
                },
                {
                  name: 'REVLIMID<sup>®</sup>(lenalidomide)/POMALYST<sup>®</sup>(pomalidomide)',
                  title: 'REVLIMID®(lenalidomide)/POMALYST®(pomalidomide)',
                  href: '/menu/revlimid-pomalyst'
                },
                {
                  name: 'SPRYCEL<sup>®</sup>(dasatinib)',
                  title: 'SPRYCEL®(dasatinib)',
                  href: '/menu/sprycel'
                },
                {
                  name: 'THALIDOMIDE CELGENE<sup>®</sup>(thalidomide) ',
                  external: true,
                  noPopup: true,
                  href: 'https://media2.celgene.com/content/uploads/sites/22/Thalidomide_Korea_PIL.pdf',
                },
                {
                  name: 'VIDAZA<sup>®</sup>(azacitidine)',
                  title: 'VIDAZA®(azacitidine)',
                  href: '/menu/vidaza'
                },
              ]
            },
            {
              name: 'Oncology',
              href: '/menu/opdivo-yervoy',
              child: this.isLogin && [
                {
                  name: 'OPDIVO<sup>®</sup>(nivolumab)/YERVOY<sup>®</sup>(ipilimumab)',
                  title: 'OPDIVO®(nivolumab)/YERVOY®(ipilimumab)',
                  href: '/menu/opdivo-yervoy'
                },
                {
                  name: 'ABRAXANE<sup>®</sup> <span>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</span>',
                  title: 'ABRAXANE®(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)',
                  href: '/menu/abraxane',
                },
                // {
                //   name: 'TAXOL<sup>®</sup>(paclitaxel)',
                //   external: true,
                //   noPopup: true,
                //   href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/taxol-pi.pdf',
                // },
              ]
            },
            {
              name: 'Immunology',
              href: '/menu/zeposia',
              child: this.isLogin && [
                {
                  name: 'ZEPOSIA<sup>®</sup>(ozanimod)',
                  title: 'ZEPOSIA®(ozanimod)',
                  href: '/menu/zeposia',
                },
                {
                  name: 'ORENCIA<sup>®</sup>(abatacept)',
                  title: 'ORENCIA®(abatacept)',
                  href: '/menu/orencia',
                },
                {
                  name: 'SOTYKTU<sup>™</sup>(deucravacitinib)',
                  title: 'SOTYKTU™(deucravacitinib)',
                  href: '/menu/sotyktu',
                }
              ]
            },
            {
              name: 'Virology',
              href: '/menu/baraclude',
              child: this.isLogin && [
                {
                  name: 'BARACLUDE<sup>®</sup>(entecavir)',
                  title: 'BARACLUDE®(entecavir)',
                  href: '/menu/baraclude',
                },
                {
                  name: 'REYATAZ<sup>®</sup>(atazanavir) ',
                  external: true,
                  noPopup: true,
                  href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/reyataz-pi.pdf',
                },
                {
                  name: 'EVOTAZ<sup>®</sup>(atazanavir and cobicistat) ',
                  external: true,
                  noPopup: true,
                  href: 'https://www.bms.com/assets/bms/korea/documents/pi_pdfs/evotaz-pi.pdf',
                },
              ]
            },
            {
              name: 'Cardiology',
              href: '/menu/eliquis',
              child: this.isLogin && [
                {
                  name: 'ELIQUIS<sup>®</sup>(apixaban)',
                  title: 'ELIQUIS®(apixaban)',
                  href: '/menu/eliquis',
                },
                {
                  name: 'CAMZYOS<sup>™</sup>(mavacamten)',
                  title: 'CAMZYOS™(mavacamten)',
                  href: '/menu/camzyos',
                },
              ]
            },
          ]
        },
        //제품 끝
        //--------------------------------------------------------------------
        {
          name: '웨비나 & VOD',
          href: '/webinar-symposium/webinar-live',
          depth: 2,
          child: [
            {
              name: '웨비나 라이브',
              href: '/webinar-symposium/webinar-live',
            },
            {
              name: '웨비나 다시보기',
              href: '/webinar-symposium/webinar-vod',
            },
            {
              name: 'VOD Contents',
              href: '/webinar-symposium/symposium-vod',
            },
            // {
            //   name: '이용안내',
            //   href: '/webinar-symposium/user-guide',
            // }
          ]
        },
        {
          name: 'BMS 직원방문 및 연락 요청',
          href: '/request-service',
          depth: 1,
        },
        {
          name: 'BMS 소식',
          href: '/news/news-and-notice',
          depth: 2,
          child: [
            {
              name: '뉴스 & 공지사항',
              href: '/news/news-and-notice'
            },
            {
              name: '사회공헌활동',
              href: '/news/social-contribute'
            },
            {
              name: '설문조사참여',
              href: '/news/survey-event',
              disabled: true,
            },
            {
              name: 'BMS 웹매거진',
              href: '/news/web-magazine',
              disabled: true,
            }
          ]
        },
        {
          name: '의학정보요청 및 이상사례보고',
          href: '/request-report',
          depth: 1,
        },
        {
          name: '파이프라인',
          href: '',
          depth: 2,
          child: [
            {
              name: 'Pipeline at a Glance',
              external: true,
            },
            {
              name: 'Pre-Approval Access',
              external: true,
            },
            // {
            //   name: 'Pipeline Trials',
            //   href: 'https://www.bmsscience.com/pipeline-trials/',
            //   external: true,
            // },
          ]
        }
      ]
    }
  },
};
</script>
<style scoped lang="scss">
.visibleMenu {
  visibility: hidden;
}
</style>
